import React, { Component } from 'react'
import S3 from 'react-aws-s3';
import 'react-dropdown/style.css';
import ProjectIcon from '../assets/Icon_Project'
import TicketList from '../components/TicketsList'
import DescriptionTree from '../components/DescriptionTree'
import Loader from '../assets/Loader'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select';



const apiUrl = `${process.env.REACT_APP_API}` 
const user = process.env.REACT_APP_USER 
const jiraUrl = process.env.REACT_APP_JIRA_URL 
const jiraPostUrl = process.env.REACT_APP_POST_URL 
const password = process.env.REACT_APP_PASSWORD

const config = {
    bucketName: process.env.REACT_APP_AWS_BUCKET,
    dirName: "documents" /* optional */,
    region: process.env.REACT_APP_AWS_REGION,
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
};

const S3Client = new S3(config);

const options = [
  'Loading Projects...'
];
class Home extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedProject: null,
            selectedProjectKey: null,
            fileToUpload: null,
            projectsLoader: false,
            projectList: options,
            globalLoader: false,
            showTree: false,
            showTicketsContent: false,
            uploadedFileName:null,
            levelTreeData: null,
            finalEpicList: null,
            selectedOption: null,        
        }
    }

    

    componentDidMount = async() => {
        this.setState({
            ...this.state,
            projectsLoader: true,
        })

        const reqBody = {
            url: jiraPostUrl,
            user,
            password
        }
        
        // * Fetch Project List Api
        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*'
            },
            body: JSON.stringify(reqBody)
        }   
        const projectList = await fetch(`${apiUrl}/Project`, options).then(res => res.json()).then(data => data).catch(err => console.log(err))
        const newListArr = []
        if(projectList){
            projectList.map(project => {
                newListArr.push({
                    value: project.Name,
                    label: project.Name,
                    id: project.Id,
                    key: project.Key,
                })
            })
        }

        this.setState({
            ...this.state,
            projectList: newListArr,
            projectsLoader: false,
        })

    }

    // * On Changing Project from Dropdown
    handleDropdownChange = (selected) => {        
        const selectedKey = this.state.projectList.find(project => project.value === selected.value).key
        this.setState({         
            selectedProject:selected.value,
            selectedProjectKey: selectedKey,
        })
    }

    // * On File Upload
    handleFileChange = async(e) => {
        const extension_reg = /(?:\.([^.]+))?$/;
        const file = e.target.files[0]               
        if(file!==undefined){
            const ext = extension_reg.exec(file.name)[1];  
           
            if(ext==='docx'){
                this.setState({
                    ...this.state,
                    fileToUpload: file,
                    globalLoader: true,
                })

                await S3Client.uploadFile(file,file.name)            
                .then( async(data) => {
                    toast.success("File uploaded successfully")
                    this.setState({
                        ...this.state,
                        globalLoader: false,
                        uploadedFileName:data.key
                    })            
                })
                .catch(err => {
                    console.error(err)
                    toast.error("Something went wrong");
                    this.setState({
                        ...this.state,
                        globalLoader: false,                        
                    })  
                })
            }else{
                toast.error("Invalid file format. Please upload docx file only");
            }
        }
    }   


    // * On Submit Click with Filname
    handleSubmit = async() => {
        this.setState({
            ...this.state,
            globalLoader: true,
        })

        const fileName = this.state.uploadedFileName
        const apiBodyData = {
            file: fileName,
            bucket: process.env.REACT_APP_AWS_BUCKET
        }

        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*'
            },
            body: JSON.stringify(apiBodyData)
        } 

        const rdsLevelResponse = await fetch(`${apiUrl}/RDS`, options)
        const apiLevelResponse = await  rdsLevelResponse.json()
        this.setState({
            ...this.state,
            levelTreeData: apiLevelResponse,
            showTree: true,
            globalLoader: false,
        })
    }

    // * Create Jira Tickets

    handleCreateJiraTickets = async(ticketsData) => {
        this.setState({
            ...this.state,
            globalLoader: true,
        })
        const apiBodyData = {
            url: jiraPostUrl,
            user,
            password,
            key:this.state.selectedProjectKey,
            data: ticketsData
        }

        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*'
            },
            body: JSON.stringify(apiBodyData)
        } 

        const rdsLevelResponse = await fetch(`${apiUrl}/JiraAPI`, options)
        const apiLevelResponse = await  rdsLevelResponse.json()

        
        this.setState({
            ...this.state,
            globalLoader: false,
            finalEpicList: apiLevelResponse,
        })
        
        
    }

    handleChange = (selectedOption) => {
        this.setState({ selectedOption });     
        this.handleDropdownChange(selectedOption)   
      };

    render() {
        const selectedProject = this.state.selectedProject
        const selectedFile = this.state.fileToUpload
        const areProjectsloaded = this.state.projectsLoader
        const { selectedOption } = this.state;
        let fileName='';
        if(this.state.uploadedFileName!==null){
            fileName = this.state.uploadedFileName.replace('documents/','');
        }
        return (
            <>  
                {
                    this.state.globalLoader ? (
                        <div className="global_loader flex_view_xs middle center">
                            <Loader/>
                        </div>
                    ) : null
                }
                
                <div className='homepage'>
                <ToastContainer />

                    <div className="container">
                        <div className="row">
                            <div className="col-sm-4">
                                <div className="project_dropdown">
                                    <span className="project_icon">
                                        <ProjectIcon/>
                                    </span>
                                    <Select
                                        placeholder="Select Project"
                                        value={selectedOption}
                                        onChange={this.handleChange}
                                        options={this.state.projectList}
                                        classNamePrefix="project__select"
                                    />
                                    {/* <Dropdown className={`${areProjectsloaded ? 'loading_projects' : ''}`} options={this.state.projectList} onChange={(data) => this.handleDropdownChange(data)}  placeholder="Select Project" arrowClosed={<span className="arrow-closed arrow_icon" />} arrowOpen={<span className="arrow-open arrow_icon" />} /> */}
                                </div>
                                
                                <div className="btn">
                                    <button className={`submit_btn ${selectedOption && selectedFile ? '' : 'disabled' }`} onClick={() => this.handleSubmit()} disabled={ selectedOption && selectedFile ? false : true }>Submit</button>
                                </div>
                            </div>

                            <div className="col-sm-8">
                                <div className="fileUploadArea">
                                    <div className="main_input">                                      
                                        <input type="file" name='fileupload' id='fileupload' accept="application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document" onChange={(e) => this.handleFileChange(e)} />
                                        <div className="customUploadBox flex_view_xs middle center">
                                            <div className="icon">
                                                <svg width="45" height="60" viewBox="0 0 45 60">
                                                    <defs>
                                                        <linearGradient id="linear-gradient" x1="0.827" y1="0.933" x2="0.049" gradientUnits="objectBoundingBox">
                                                        <stop offset="0" stopColor="#292929"/>
                                                        <stop offset="1" stopColor="#87a878"/>
                                                        </linearGradient>
                                                    </defs>
                                                    <path id="Icon_awesome-file-word" data-name="Icon awesome-file-word" d="M26.25,15.938V0H2.813A2.806,2.806,0,0,0,0,2.813V57.188A2.806,2.806,0,0,0,2.813,60H42.188A2.806,2.806,0,0,0,45,57.188V18.75H29.063A2.821,2.821,0,0,1,26.25,15.938ZM32.941,30h2.8a1.406,1.406,0,0,1,1.371,1.723L32.66,51.41a1.394,1.394,0,0,1-1.371,1.09H26.836a1.4,1.4,0,0,1-1.359-1.066c-3.023-12.129-2.437-9.516-3-12.949h-.059c-.129,1.676-.281,2.039-3,12.949A1.4,1.4,0,0,1,18.059,52.5H13.711a1.4,1.4,0,0,1-1.371-1.1L7.91,31.711A1.4,1.4,0,0,1,9.281,30h2.871a1.405,1.405,0,0,1,1.383,1.137c1.828,9.141,2.355,12.832,2.461,14.32.188-1.2.855-3.832,3.445-14.379a1.4,1.4,0,0,1,1.371-1.066h3.41a1.405,1.405,0,0,1,1.371,1.078c2.813,11.766,3.375,14.531,3.469,15.164-.023-1.312-.3-2.086,2.531-15.141A1.359,1.359,0,0,1,32.941,30ZM45,14.285V15H30V0h.715a2.81,2.81,0,0,1,1.992.82L44.18,12.3A2.8,2.8,0,0,1,45,14.285Z" fill="url(#linear-gradient)"/>
                                                </svg>
                                            </div>
                                            
                                            <div className="text">
                                                {
                                                    (fileName!=='')?
                                                    fileName
                                                    :
                                                    <>
                                                    {`Drop your document(.docx) here, or`} <span className="hl">Browse</span> to upload and create relevant JIRA stories
                                                    </>
                                                }
                                                
                                            </div>
                                        </div>
                                    </div>
                                    
                                    {/* <div className="error_msg hide">
                                        The file format must be either '.doc' or '.docx'
                                    </div> */}
                                </div>
                            </div>
                        </div>
                        
                        {/* <DescriptionTree treeData={this.state.levelTreeData}/> */}
                        {
                            this.state.showTree && this.state.levelTreeData && this.state.levelTreeData.length > 0 && <DescriptionTree treeData={this.state.levelTreeData} createTickets={(ticketsData)=> this.handleCreateJiraTickets(ticketsData)}/>
                        }
                        
                        {/* {
                            this.state.showTicketsContent && <TicketList/>
                        } */}
                        
                        {
                            this.state.finalEpicList && this.state.levelTreeData.length > 0 && <TicketList createdTickets={this.state.finalEpicList} />
                        } 
                        {/* <TicketList createdTickets={null}/>  */}
                        
                        
                        
                    </div>
                </div>
            </>
        )
    }
}

export default Home
