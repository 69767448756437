import React, { useState, useEffect, useRef} from 'react'
import {SlideDown} from 'react-slidedown'
import 'react-slidedown/lib/slidedown.css'
import IconEpic from '../assets/Icon_Epic'
import IconStory from '../assets/Icon_Story'

const  TicketsList = ({createdTickets}) => {
    const listRef = useRef(null)
    const [ currentAccordion, setCurrentAccordion ] = useState({
        accordion: "epic_0",
        active: true
    });

    const [allHidden, setAllHidden] = useState(false)

    const toggleAccordion = (e) => {
        setAllHidden(false)
        const accordion = e.currentTarget.getAttribute('data-item')
        setCurrentAccordion({
            accordion: accordion,
            active: !currentAccordion.active,
        })
    }

    const storiesData = []
    createdTickets.data.map((epic,index1) => {
        const story = epic.Epic.Story.map((story, index2) => {
            story.epicIndex = `epic_${index1}`
            return story
        }  )
        storiesData.push(epic.Epic.Story)
    })

    useEffect(() => {
        
        const listArr = Array.from(document.querySelectorAll('.storyList .story_ul li'))
        // console.log(listArr);
        const alltoHide = listArr.every(item => item.classList.contains('hide'))
        alltoHide ? setAllHidden(true) :  setAllHidden(false)

    },[currentAccordion])
    
    const storiesDataFlat = storiesData.flat()

    return (
        <div className='ticketList'>
            <div className="row">
                <div className="col-xs-12 col-sm-6 col-md-4">
                    <p className='title'>List of Epics</p>
                    <div className="epicList">
                        {
                            createdTickets.data.map( (item, index) => (

                            <div className="epicItem" key={item.Epic.id} >
                                <div className={`epicHeader flex_view_xs middle ${currentAccordion.accordion === `epic_${index}` ? 'active': ''}`} data-epic={item.Epic.key} data-item={`epic_${index}`} onClick={(e) => toggleAccordion(e) }>
                                    <span className="icon"> <IconEpic/> </span>
                                    <span className="epic_name"> <a href={`https://jira.o2h.com/jira/browse/${item.Epic.key}`} target='_blank'> {item.Epic.Title} </a> </span>
                                    <span className="ticket">{item.Epic.key}</span>
                                </div>
                                <SlideDown className={'my-dropdown-slidedown'}>
                                    {
                                        currentAccordion.accordion === `epic_${index}` ? (
                                        <div className="epicDescr">
                                            {item.Epic.Description}
                                        </div>

                                        ) : null
                                    }
                                </SlideDown>

                            </div>

                            ))
                        }

                        
                    </div>
                </div>

                {
                    
                    <div ref={listRef} className="col-xs-12 col-sm-6 col-md-8">
                        <p className='title'>List of JIRA Stories</p>
                        <div className='storyList'>
                        {
                            !allHidden ? (
                                <ul className='story_ul'>
                                {
                                    storiesDataFlat.map((story,index) => {
                                        return  <li className={`flex_view_xs middle space-between ${currentAccordion.accordion === story.epicIndex ? 'show' : 'hide' }`} key={story.id} data-epic={story.epicIndex}>
                                            <div className='icon'> <IconStory/> </div>
                                            <div className='descr'> <a href={`https://jira.o2h.com/jira/browse/${story.key}`} target='_blank'> {story.Title}  </a></div>
                                            <div className='ticket_num'> {story.key} </div>
                                        </li>
                                    })
                                }
                                    
                                </ul>

                            ) : (
                                <div className='text-center no_story'> No Story Found </div>
                            )
                        }
                        </div>      

                    </div>

                    
                }
            </div>
        </div>
    )
}

export default TicketsList
