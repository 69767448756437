import React, { useState } from 'react'
import * as Icon from "react-icons/fi";
import Checkbox from "react-custom-checkbox";


const  DescriptionTree = ({treeData, createTickets}) => {

    let treeArrOfObj = []

    // * Filtering All Data with Integer Values only
    let allInts = treeData.filter(item => {
        if(item.ID.trim() !== '' && item.Title.trim() !== '' ){
            if(!item.ID.includes(".")){
                return item
            }
        }
    })

    // * Creating Array of Objects for sorted & Same Level Data
    allInts.map( (item, index) => {
        var itemObj = {}
        
        var allStartWithItemOriginal = treeData.filter( num => {
            if( num.ID ===item.ID || (num.ID.startsWith(item.ID) && num.ID.includes('.'))){
                return num
            } 
        } )

        var allStartWithItemCopy = [...allStartWithItemOriginal]
        
        var sortedAllStartWithItem = allStartWithItemCopy.sort((a,b) =>  b.ID.length - a.ID.length)[0].ID.split('.')
        
        itemObj.levelCount = sortedAllStartWithItem.length

        itemObj.intNum = index + 1
        
        itemObj.allRelevantLevels = allStartWithItemOriginal
        
        treeArrOfObj.push(itemObj)
    })

    const [ sortedTreeData, setSortedTreeData ] = useState(treeArrOfObj)
    const [ clickedLevel, setClickedLevel ] = useState([])

    const selectAllChild = (e,flag) => {
        const idClicked = e.currentTarget.getAttribute('data-id')
        const isActive = e.currentTarget.getAttribute('data-active')
        if(isActive === 'false'){
            let idsToAdd = treeData.filter(item => item.ID.startsWith(idClicked)).map(item=> item.ID)

            let arr = []
            idsToAdd.filter(id => id===idClicked && id.includes('.'))
            for(let i=0; i<idsToAdd.length; i++){
                let iterationId = idsToAdd[i]
                if(iterationId === idClicked){
                    arr.push(iterationId)
                }
                if(iterationId.includes('.')){
                    arr.push(iterationId)
                }
            }
            idsToAdd = arr

            setClickedLevel([...clickedLevel, ...idsToAdd])

            
        }else{
            let idsToRemove = treeData.filter(item => item.ID.startsWith(idClicked)).map(item=>item.ID)
            
            let arr = []
            for(let i=0; i<idsToRemove.length; i++){
                let iterationId = idsToRemove[i]
                if(iterationId === idClicked){
                    arr.push(iterationId)
                }
                if(iterationId.includes('.')){
                    arr.push(iterationId)
                }
            }
            idsToRemove = arr

            const filteredIds = clickedLevel.filter(id => {
                if(!idsToRemove.includes(id)){
                    return id
                }
            });
            setClickedLevel([...filteredIds])
        }
    }
    
    
    const handleCreateTickets = () => {
        const filteredTickets = treeData.filter((item,index) => clickedLevel.includes(item.ID) ?  item.ID.includes('.') ? item.Type = 'Story' : item.Type = 'Epic' : null)
        createTickets(filteredTickets)
    }
    
    return (
         <div className="descrList">
            <div className="header flex_view_xs middle space-between">
                <p>Table of contents for the uploaded document</p>
                <button className={`create_btn ${clickedLevel && clickedLevel.length > 0 ? '' : 'disabled'}`} onClick={ handleCreateTickets } disabled={ clickedLevel && clickedLevel.length > 0 ? null : 'disabled'} >Create JIRA tickets</button>
            </div>
            
            <div className="titleList">
                <div className="inner">
                    <div className='firstLevel epics'> 
                        {
                            sortedTreeData && sortedTreeData.map( treeData => {

                                var intValItem = treeData.allRelevantLevels.find(item => !item.ID.includes('.'))

                                return(
                                    <div className={`epicItem totalLevels_${treeData.levelCount}`} key={intValItem.ID}>
                                        <span className='test' data-active={clickedLevel.includes(intValItem.ID) ? true : false} data-id={intValItem.ID} onClick={(e) => selectAllChild(e,'first')}>
                                            <Checkbox
                                                icon={<Icon.FiCheck color="#D9ECCF" size={14} />}
                                                name="my-input"
                                                checked={clickedLevel.includes(intValItem.ID) ? true : false}
                                                onChange={(value,e) => {}}
                                                borderColor="#D9ECCF"
                                                style={{ cursor: "pointer" }}
                                                labelStyle={{ marginLeft: 15, userSelect: "none" }}
                                                label={`${intValItem.ID} ${intValItem.Title}`}
                                                containerClassName="customCheckbox"
                                            />
                                        </span>

                                        <div className="stories level_2">
                                            {
                                                treeData.allRelevantLevels.filter(item => item.ID.startsWith(intValItem.ID) && item.ID.split('.').length === 2).map( secondLevelItem => {
                                                    return(
                                                    <div className="storyItem" key={secondLevelItem.ID}>
                                                        <span className='test' data-active={clickedLevel.includes(secondLevelItem.ID) ? true : false} data-id={secondLevelItem.ID} onClick={(e) => selectAllChild(e,'second')}>
                                                            <Checkbox
                                                                icon={<Icon.FiCheck color="#D9ECCF" size={14} />}
                                                                name="my-input"
                                                                checked={clickedLevel.includes(secondLevelItem.ID) ? true : false}
                                                                // onChange={(value) => {
                                                                // let p = {
                                                                //     isTrue: value,
                                                                // };
                                                                // return alert(value);
                                                                // }}
                                                                borderColor="#D9ECCF"
                                                                style={{ cursor: "pointer" }}
                                                                labelStyle={{ marginLeft: 15, userSelect: "none" }}
                                                                label={`${secondLevelItem.ID} ${secondLevelItem.Title}`}
                                                                containerClassName="customCheckbox"
                                                            />

                                                        </span>
                                                        <div className="stories level_3">
                                                        {
                                                            treeData.allRelevantLevels.filter(item => item.ID.startsWith(secondLevelItem.ID) && item.ID.split('.').length === 3).map( thirdLevel => {
                                                                return(
                                                                <div className={`storyItem`} key={thirdLevel.ID}>
                                                                    <span className='test' data-active={clickedLevel.includes(thirdLevel.ID) ? true : false} data-id={thirdLevel.ID} onClick={(e) => selectAllChild(e,'third')}>
                                                                        <Checkbox
                                                                            icon={<Icon.FiCheck color="#D9ECCF" size={14} />}
                                                                            name="my-input"
                                                                            checked={clickedLevel.includes(thirdLevel.ID) ? true : false}
                                                                            // onChange={(value) => {
                                                                            // let p = {
                                                                            //     isTrue: value,
                                                                            // };
                                                                            // return alert(value);
                                                                            // }}
                                                                            borderColor="#D9ECCF"
                                                                            style={{ cursor: "pointer" }}
                                                                            labelStyle={{ marginLeft: 15, userSelect: "none" }}
                                                                            label={`${thirdLevel.ID} ${thirdLevel.Title}`}
                                                                            containerClassName="customCheckbox"
                                                                        />

                                                                    </span>

                                                                    <div className="stories level_4"> 
                                                                        {
                                                                            treeData.allRelevantLevels.filter(item => item.ID.startsWith(thirdLevel.ID) && item.ID.split('.').length === 4).map( fourthLevel => {
                                                                                return(
                                                                                <div className={`storyItem`} key={fourthLevel.ID}>
                                                                                    <span className='test' data-active={clickedLevel.includes(fourthLevel.ID) ? true : false} data-id={fourthLevel.ID} onClick={(e) => selectAllChild(e,'fourth')}>
                                                                                        <Checkbox
                                                                                            icon={<Icon.FiCheck color="#D9ECCF" size={14} />}
                                                                                            name="my-input"
                                                                                            checked={clickedLevel.includes(fourthLevel.ID) ? true : false}
                                                                                            // onChange={(value) => {
                                                                                            // let p = {
                                                                                            //     isTrue: value,
                                                                                            // };
                                                                                            // return alert(value);
                                                                                            // }}
                                                                                            borderColor="#D9ECCF"
                                                                                            style={{ cursor: "pointer" }}
                                                                                            labelStyle={{ marginLeft: 15, userSelect: "none" }}
                                                                                            label={`${fourthLevel.ID} ${fourthLevel.Title}`}
                                                                                            containerClassName="customCheckbox"
                                                                                            
                                                                                        />

                                                                                    </span>
                                                                                    
                                                                                    
                                                                                </div>

                                                                                )
                                                                            })
                                                                        }
                                                                    
                                                                    </div>
                                                                    
                                                                </div>

                                                                )
                                                            })
                                                        }

                                                        </div>
                                                    </div>

                                                    )
                                                })
                                            }

                                        </div>

                                    </div>
                                )

                            })
                        }
                    </div>
                </div>
            </div>

        </div>
    )
}

export default DescriptionTree
