import React from 'react'

function Icon_Story() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={48} height={42}>
      <defs>
        <filter
          id="prefix__a"
          x={0}
          y={0}
          width={48}
          height={48}
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy={3} />
          <feGaussianBlur stdDeviation={2} result="blur" />
          <feFlood floodColor="#a5668b" floodOpacity={0.361} />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g data-name="Group 55">
        <g filter="url(#prefix__a)">
          <path
            data-name="Path 2233"
            d="M24 3A18 18 0 116 21 18 18 0 0124 3z"
            fill="#a5668b"
          />
        </g>
        <path
          data-name="Icon awesome-bookmark"
          d="M18 28.547V14.458A1.458 1.458 0 0119.458 13H28.2a1.458 1.458 0 011.46 1.458v14.089l-5.83-3.4z"
          fill="#fff"
        />
      </g>
    </svg>
  )
}

export default Icon_Story
