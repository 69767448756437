import React from 'react'

function Icon_Epic() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={48}
            height={48}
            viewBox="0 0 48 48"
        >
            <defs>
                <filter
                    id="prefix__a"
                    x={0}
                    y={0}
                    width={48}
                    height={48}
                    filterUnits="userSpaceOnUse"
                >
                    <feOffset dy={3} />
                    <feGaussianBlur stdDeviation={2} result="blur" />
                    <feFlood floodColor="#87a878" floodOpacity={0.361} />
                    <feComposite operator="in" in2="blur" />
                    <feComposite in="SourceGraphic" />
                </filter>
            </defs>
            <g data-name="Group 55">
                <g filter="url(#prefix__a)">
                    <path
                        data-name="Path 2233"
                        d="M24 3A18 18 0 116 21 18 18 0 0124 3z"
                        fill="#87a878"
                    />
                </g>
                <path
                    data-name="Icon weather-lightning"
                    d="M23.27 32.28l.433-.176 3.08-14.416q.03-.36-.288-.233l-3.16 1.277.877-7.408c.02-.24-.099-.31-.344-.21l-4.239 1.712a.436.436 0 00-.242.33l.23 9.477c.06.209.167.282.334.215l3.06-1.237z"
                    fill="#fff"
                />
            </g>
        </svg>
    )
}

export default Icon_Epic
