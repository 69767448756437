import React from 'react'

function Icon_Project() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={14.017}
      height={11.213}
      viewBox="0 0 14.017 11.213"
    >
      <path
        data-name="Icon awesome-project-diagram"
        d="M8.41 7.008h-2.8a.7.7 0 00-.7.7v2.8a.7.7 0 00.7.7h2.8a.7.7 0 00.7-.7v-2.8a.7.7 0 00-.7-.7zM4.2.7a.7.7 0 00-.7-.7H.7a.7.7 0 00-.7.7v2.8a.7.7 0 00.7.7h2.1L4.4 7a1.4 1.4 0 011.207-.7h.006L4.2 3.844V2.8h4.911V1.4H4.2zm9.116-.7h-2.8a.7.7 0 00-.7.7v2.8a.7.7 0 00.7.7h2.8a.7.7 0 00.7-.7V.7a.7.7 0 00-.7-.7z"
        fill="#6b6b6b"
      />
    </svg>
  )
}

export default Icon_Project
