import React from 'react'
import { Link } from 'react-router-dom'

function Header() {
    return (
        <div className='header'>
            <div className="container">
                <div className="logo_img">
                    <Link to='/'>
                        <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" width="117.384" height="72.711" viewBox="0 0 117.384 72.711">
                            <g id="Layer_1" data-name="Layer 1">
                                <path id="Path_1" data-name="Path 1" d="M117.16,165.155a1.076,1.076,0,0,1,.724-.425h.356v1.4h2.149v.617a.82.82,0,0,1-.919.735H118.24v2.424c0,.8.356,1.115.977,1.115a2.194,2.194,0,0,0,1.115-.218h.057v.551c0,.632-.46,1.034-1.356,1.034a2.069,2.069,0,0,1-2.321-2.3v-3.723A1.532,1.532,0,0,1,117.16,165.155Z" transform="translate(-72.008 -101.639)" fill="#64635f"/>
                                <path id="Path_2" data-name="Path 2" d="M134.147,171.932h-1.011a1.622,1.622,0,0,0,1.735,1.275,2.807,2.807,0,0,0,1.9-.586h.057v.483c0,.793-.54,1.4-2.241,1.4a3.064,3.064,0,0,1-3.064-3.114c0-2.068,1.264-3.367,3.033-3.367a2.394,2.394,0,0,1,2.512,2.4C137.065,171.564,136.192,171.932,134.147,171.932Zm.287-2.62a1.325,1.325,0,0,0-1.3,1.321v.207h.689c1.459,0,1.792-.161,1.792-.6C135.606,169.622,135.15,169.312,134.434,169.312Z" transform="translate(-81.148 -103.671)" fill="#64635f"/>
                                <path id="Path_3" data-name="Path 3" d="M156.888,170.29a1.9,1.9,0,0,0-1.678-.643,1.681,1.681,0,0,0-1.689,1.643,1.706,1.706,0,0,0,1.712,1.9,2.02,2.02,0,0,0,1.632-.574h.061v.712c0,.747-.724,1.287-1.838,1.287a3.125,3.125,0,0,1-3.171-3.091,3.183,3.183,0,0,1,3.344-3.286,1.509,1.509,0,0,1,1.689,1.333v.724Z" transform="translate(-93.732 -103.798)" fill="#64635f"/>
                                <path id="Path_4" data-name="Path 4" d="M176.653,171.269a.954.954,0,0,1-1.046-1.034v-2.528c0-1.011-.552-1.413-1.229-1.413-.483,0-1.115.383-1.115,1.459v3.516h-.506a.938.938,0,0,1-1.057-1.034V163h.494a.955.955,0,0,1,1.092.988v1.769a1.75,1.75,0,0,1,1.344-.827,2.385,2.385,0,0,1,2.539,2.712v3.631Z" transform="translate(-105.937 -100.572)" fill="#64635f"/>
                                <path id="Path_5" data-name="Path 5" d="M198.5,174.366a1.023,1.023,0,0,1-1.126-1.011V170.9c0-1.046-.46-1.505-1.252-1.505-.666,0-1.126.506-1.126,1.39v3.585h-.46a1,1,0,0,1-1.08-1.011v-2.681a2.737,2.737,0,0,1,5.473.08v3.608Z" transform="translate(-119.366 -103.669)" fill="#64635f"/>
                                <path id="Path_6" data-name="Path 6" d="M217.534,168a2.94,2.94,0,0,1,2.93,3.137,3.024,3.024,0,0,1-3.064,3.344,2.93,2.93,0,0,1-2.93-3.125A3.035,3.035,0,0,1,217.534,168Zm-.138,1.379c-.885,0-1.333.735-1.333,1.758,0,1.229.448,1.965,1.471,1.965.9,0,1.333-.735,1.333-1.746.019-1.229-.46-1.965-1.471-1.965Z" transform="translate(-132.329 -103.657)" fill="#64635f"/>
                                <path id="Path_7" data-name="Path 7" d="M238.985,171.312a1.746,1.746,0,0,1-1.965-1.915v-6.411h.437a.973.973,0,0,1,1.1,1.034v5.216c0,.62.322.816,1.046.816h.414v.333C240.019,171.047,239.663,171.312,238.985,171.312Z" transform="translate(-146.243 -100.557)" fill="#64635f"/>
                                <path id="Path_8" data-name="Path 8" d="M252.574,168a2.94,2.94,0,0,1,2.93,3.137,3.024,3.024,0,0,1-3.064,3.344,2.93,2.93,0,0,1-2.93-3.125A3.035,3.035,0,0,1,252.574,168Zm-.138,1.379c-.885,0-1.333.735-1.333,1.758,0,1.229.448,1.965,1.471,1.965.9,0,1.333-.735,1.333-1.746,0-1.229-.46-1.965-1.471-1.965Z" transform="translate(-153.949 -103.657)" fill="#64635f"/>
                                <path id="Path_9" data-name="Path 9" d="M276.985,172.637c0,.253.023.586.023.793a2.839,2.839,0,0,1-3.033,2.941c-1.275,0-2.149-.62-2.149-1.356v-.7h.069a2.735,2.735,0,0,0,1.965.666c1.115,0,1.62-.529,1.62-1.425a3.134,3.134,0,0,0,0-.322c0-.092,0-.253,0-.31v-.149a1.583,1.583,0,0,1-1.6.919,2.444,2.444,0,0,1-2.574-2.5,3,3,0,0,1,3.148-3.16,2.493,2.493,0,0,1,2.528,2.712V171.8A6.787,6.787,0,0,0,276.985,172.637Zm-4.113-1.643a1.271,1.271,0,0,0,1.229,1.4,1.38,1.38,0,0,0,1.321-1.563c0-.988-.483-1.436-1.149-1.436A1.472,1.472,0,0,0,272.871,170.994Z" transform="translate(-167.398 -103.675)" fill="#64635f"/>
                                <path id="Path_10" data-name="Path 10" d="M293.325,176.605a1,1,0,0,1-1.08-.954v-.3l.873-.057c.563-.034.766-.264,1.092-1.149A19.625,19.625,0,0,1,292,168.39h.816a.9.9,0,0,1,.9.724,13.332,13.332,0,0,0,1.241,3.573,17.457,17.457,0,0,0,1.206-4.3h.666c.494,0,.724.218.724.724a23.662,23.662,0,0,1-1.367,4.148C295.064,175.973,294.44,176.605,293.325,176.605Z" transform="translate(-180.165 -103.897)" fill="#64635f"/>
                                <path id="Path_11" data-name="Path 11" d="M20.448,55.389C8.809,55.389,0,46.821,0,35.461,0,22.677,8.836,13.75,21.49,13.75l37.859.084c6.875,0,14.171,4.263,14.171,12.16,0,5.722-1.915,7.867-8.9,12.769L58.3,43.171a20.658,20.658,0,0,0-2.681,2.321l-1.13,1.275H82.344V0h2.869c3.018,0,6.618.992,6.618,5.745v17.02s3.1-6.45,13.926-6.45c5.6,0,11.62,4.14,11.62,13.229V54.439H113.8c-5.213,0-6.2-2.137-6.2-6.082V31.452a7.152,7.152,0,0,0-7.438-7.5c-5.029,0-8.043,2.892-8.043,7.733v22.83l-43.259-.172c-2.34,0-4.711-1.394-4.711-4.06,0-3.16,2.149-6.262,6.974-10.057l9.464-7.38a7,7,0,0,0,2.976-5.607c0-3.853-5.745-5.649-9.64-5.649H37.832l.766,1.191a20.441,20.441,0,0,1,3.321,11.34C41.938,47.438,33.907,55.389,20.448,55.389Zm.061-33.941c-5.462,0-9.9,5.534-9.9,12.332,0,11.808,5.887,14.286,10.827,14.286,5.829,0,9.9-5.105,9.9-12.417C31.333,26.76,27.288,21.448,20.509,21.448Z" fill="#7ea35d"/>
                            </g>
                        </svg>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default Header
