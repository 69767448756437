import React, { Component } from 'react'
import './scss/app.scss'
import Header from './components/Header'
import { Switch, Route } from 'react-router-dom';
import Home from './components/Home'


class App extends Component {
    
    render() {
        return (
            <div>
                <Header />
                <Switch>
                    <Route exact path='/' component={Home} />
                </Switch>
            </div>
        )
    }
}

export default App
